<!--
 * @Description: 房源营销 优惠券
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:49:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-22 17:27:17
-->
<template>
  <div v-loading="loading">
    <el-card class="box">
      <div class="header-title">可叠加优惠</div>
      <el-row>
        <el-card
          shadow="hover"
          class="card"
          v-for="item in activityList.filter((x) => x.is_multiple)"
          :key="item.id"
          @click="openEdit(item)"
        >
          <div class="info">
            <span class="name">{{ item.type_text }}</span>
            <span></span>
          </div>
          <div class="help-block">已设置房源数量{{ item.join_house_count }}/{{ item.house_count }}</div>
        </el-card>
      </el-row>
    </el-card>
    <el-card class="box">
      <div class="header-title">
        不可叠加优惠
        <small>下列优惠若房客同时符合多个，将享受优惠力度最大的优惠</small>
      </div>
      <el-row>
        <el-card
          shadow="hover"
          class="card"
          v-for="item in activityList.filter((x) => !x.is_multiple)"
          :key="item.id"
          @click="openEdit(item)"
        >
          <div class="info">
            <span class="name">{{ item.type_text }}</span>
            <span></span>
          </div>
          <div class="help-block">已设置房源数量{{ item.join_house_count }}/{{ item.house_count }}</div>
        </el-card>
      </el-row>
    </el-card>
  </div>

  <!-- 设置弹框 -->
  <el-drawer
    :title="`${form?.type_text}`"
    v-model="visibleDrawer"
    direction="rtl"
    size="1000px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-scrollbar v-loading="loading_dtl">
      <div
        style="
          white-space: pre-line;
          color: #909399;
          font-size: 14px;
          margin-bottom: 20px;
        "
      >{{ form?.description }}</div>
      <el-timeline>
        <el-timeline-item>
          <p>1、选择房源</p>
          <el-alert type="info" :closable="false" v-if="form.is_general" title="适用所有房源"></el-alert>
          <div v-if="!form.is_general">
            <el-alert type="info" :closable="false" :title="`已选 ${selectionList.length} 个房源`">
              <el-tag
                :closable="true" 
                v-for="(item, i) in selectionList"
                :key="item.id"
                style="margin-right: 20px; margin-bottom: 10px"
                @close="selectionList.splice(i, 1)"
              >
                {{ item.name }}(<span style="color:var(--el-color-danger)">房间：{{ item.stores.map(x=>x.house_no).join(',') }}</span>)
              </el-tag>
            </el-alert>
            <el-popover :width="800" placement="bottom-start" trigger="click">
              <template #reference>
                <el-button type="primary" link icon="plus">添加房源</el-button>
              </template>
              <el-form :model="query" ref="form" :inline="true">
                <el-form-item label>
                  <el-input v-model="query.q" placeholder="房源、内部名称"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
                </el-form-item>
              </el-form>

              <el-table
                ref="houseTable"
                :data="tableData.data"
                @selection-change="selectionChange"
                height="400"
                row-key="id"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  reserve-selection
                ></el-table-column>
                <el-table-column
                  label="编号"
                  prop="id"
                  width="80"
                ></el-table-column>
                <el-table-column label="房源标题" prop="name">
                  
                </el-table-column>
                <el-table-column 
                  label="关联房间" 
                >
                <template #default="scope">
                  <el-tag size="small" v-for="item in scope.row.stores" :key="item.id" style="margin-right:5px;">{{ item.house_no}}</el-tag>
                </template>
              </el-table-column>
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <el-tag
                      :type="
                        scope.row.status == 0
                          ? 'info'
                          : scope.row.status == 5
                          ? ''
                          : scope.row.status == 10
                          ? 'success'
                          : scope.row.status == 15
                          ? 'danger'
                          : ''
                      "
                      >{{ scope.row.status_text }}</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                v-if="tableData.counts > 0"
                @size-change="pageSizeChange"
                @current-change="pageCurrentChange"
                :current-page="query.pageIndex"
                :page-size="query.pageSize"
                :page-sizes="[20, 40]"
                layout="total,prev, pager, next"
                :total="tableData.counts"
              ></el-pagination>
            </el-popover>
            <el-button type="info" link icon="remove" @click="onClearSelection"
              >清除所有</el-button
            >
          </div>
        </el-timeline-item>
        <el-timeline-item>
          <p>2、设置优惠</p>
          <el-form
            label-position="top"
            :model="form"
            ref="form"
            class="act-form"
            :rules="rules"
            label-width="100px"
          >
            <el-form-item label="入住日期范围">
              <el-row>
                <el-date-picker
                  :disabled="!form.is_allow"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  v-model="form.start_date"
                  placeholder="开始日期"
                ></el-date-picker>
                <span>-</span>
                <el-date-picker
                  :disabled="!form.is_allow"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  v-model="form.end_date"
                  placeholder="结束日期"
                ></el-date-picker>
              </el-row>
              <div class="help-block" v-if="!form.is_allow">该入住日期范围由平台设定</div>
            </el-form-item>
            <el-form-item label="自动延期" v-if="form.is_allow && form.lengthen_days > 0">
              <el-switch v-model="form.is_auto_lengthen"></el-switch>
              <div class="help-block">活动到期后自动延长{{ form.lengthen_days }}天</div>
            </el-form-item>

            <el-form-item label="优惠设置">
              <el-alert :closable="false" title="抵扣积分" type="info"  v-if="form.type == 1"> 
                <el-tag v-for="x in form.items" :closable="false" :key="x" style="margin-right:10px;margin-bottom:10px;">
                  <span>{{x.score}}积分</span>
                </el-tag>
              </el-alert>
              <el-alert type="info" :closable="false" title v-if="!form.is_general">
                <el-row :gutter="20" v-for="(item, i) in items" :key="i">
                  <el-col :span="9" v-if="[0, 2, 3, 4].findIndex((o) => o === form.type) > -1">
                    <el-row>
                      <el-col :span="8">
                        <div class="item_name">梯度{{ i + 1 }}</div>
                      </el-col>
                      <el-col :span="15">
                        <div v-if="form.type == 0">会员等级</div>
                        <div v-if="form.type == 2">提前天数</div>
                        <div v-if="form.type == 3">最少连住天数</div>
                        <div v-if="form.type == 4">几点开始</div>
                        <!-- 会员折扣 -->
                        <el-select v-model="item.level" placeholder v-if="form.type == 0">
                          <el-option
                            :label="x.name"
                            :value="x.level"
                            v-for="x in gradeList"
                            :key="x.id"
                          ></el-option>
                        </el-select>
                        <!-- 提前、连住 -->
                        <el-select
                          v-model="item.days"
                          placeholder
                          v-if="form.type == 2 || form.type == 3"
                        >
                          <el-option :label="`${d}天`" :value="d" v-for="d in 30" :key="d"></el-option>
                        </el-select>

                        <!-- 今夜特价 -->
                        <el-row v-if="form.type == 4">
                          <el-col :span="24">
                            <el-select v-model="item.start_hour" placeholder>
                              <el-option
                                :label="`${d - 1}:00`"
                                :value="d - 1"
                                v-for="d in 24"
                                :key="d"
                              ></el-option>
                            </el-select>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>

                  <el-col :span="6" v-if="form.type != 1">
                    <el-row>
                      <el-col :span="4" v-if="form.way == 2">
                        <div>优惠方式</div>
                        <el-select v-model="item.way" placeholder>
                          <el-option label="折扣" :value="0"></el-option>
                          <el-option label="立减" :value="1"></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="20" :offset="0" v-if="item.way == 0">
                        <span>售卖折扣</span>
                        <el-input v-model="item.decimal_discount" placeholder></el-input>
                        <div class="help-block">折扣范围{{ form.value_min }}~{{ form.value_max }}</div>
                      </el-col>
                      <el-col :span="20" :offset="0" v-if="item.way == 1">
                        <span>立减</span>
                        <el-input v-model="item.decimal_amount" placeholder></el-input>
                        <div class="help-block">下单立减金额</div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 积分 -->
                  <el-col :span="6" :offset="0" v-if="form.type == 1">
                    <span>抵扣积分</span>
                    <el-input v-model="item.score" placeholder></el-input>
                    <div class="help-block">最大抵扣积分不超过{{ form.score_max }}</div>
                  </el-col>
                  <!-- 删除 -->
                  <el-col :span="1" class="col-del" v-if="i > 0">
                    <el-button
                      type="danger"
                      link
                      class="text-danger"
                      size="small"
                      @click="items.splice(i, 1)"
                    >删除</el-button>
                  </el-col>
                </el-row>
                <div class="flex-row item-center">
                  <el-button
                    size="small"
                    round
                    @click="addItem"
                    v-if="form.stairway_num > 1"
                    icon="plus"
                  >添加梯度</el-button>
                  <span style="margin-left: 20px">最多可添加{{ form.stairway_num }}个梯度</span>
                </div>
              </el-alert>
            </el-form-item>
          </el-form>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>

    <template #footer v-if="!form.is_general">
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      loading: false,
      loading_dtl: false,
      visibleDrawer: false,
      saving: false,
      activityList: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //房源列表
      tableData: {
        counts: 0,
      },
      //已选择的房源列表
      selectionList: [],
      form: {},
      rules: {},
      //梯度项
      items: [],
      //会员等级
      gradeList: [],
    };
  },
  created() {
    this.loadActivity();
    this.loadHouseData();
    common_api.get_grade().then((res) => {
      if (res.code == 0) {
        this.gradeList = res.data;
      }
    });
  },
  methods: {
    /**
     * 加载所有的活动数据
     */
    loadActivity() {
      this.loading = true;
      this.$http.get("/seller/v1/activity").then((res) => {
        if (res.code == 0) {
          this.activityList = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 打开编辑框
     */
    openEdit(item) {
      this.items = [];
      this.selectionList = [];
      this.form = {};
      this.form = Object.assign({}, item);
      this.visibleDrawer = true;
      // console.log(this.form);
      if (!item.is_general) {
        this.loadDtl();
      }
    },
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading_dtl = true;
      this.$http
        .get("/seller/v1/activity/joindtl?type=" + this.form.type)
        .then((res) => {
          if (res.code == 0) {
            this.dtl = res.data;
            if (res.data) {
              this.form = Object.assign(this.form, res.data);
              this.selectionList = res.data.houses;
              this.items = res.data.items;
              if (this.items == null || this.items.length <= 0) {
                this.addItem();
              }
            } else {
              this.addItem();
            }
          }
          this.loading_dtl = false;
        });
    },
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadHouseData();
    },
    /**
     * 加载房源数据
     */
    loadHouseData() {
      this.loading = true;
      this.$http.post("seller/v1/house", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
        /**
     * 清除所有选择
     */
     onClearSelection() {
      this.selectionList = [];
      this.$refs.houseTable.clearSelection();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadHouseData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadHouseData();
    },
    /**
     * 选择发生变化
     */
    selectionChange(nodes) {
      nodes.forEach((item) => {
        if (!this.selectionList.find((x) => x.id == item.id)) {
          this.selectionList.push(item);
        }
      });
    },

    /**
     * 禁用选择的时间
     */
    disabledDate(date) {
      var now = new Date();
      now.setDate(now.getDate() - 1);
      if (date < now) return true;
      now.setFullYear(now.getFullYear() + 1);
      if (date > now) return true;
    },

    /**
     * 添加梯度
     */
    addItem() {
      if (this.items.length < this.form.stairway_num) {
        this.items.push({
          days: 1,
          way: 0,
          decimal_discount: "4",
          decimal_amount: "0",
          start_hour: 8,
          end_hour: 22,
        });
      }
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.form.items = this.items;
          this.form.houses = this.selectionList;
          this.$http.post("seller/v1/activity/join", this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success("保存成功");
              this.visibleDrawer = false;
              this.loadActivity();
            }
            this.saving = false;
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.box .header-title {
  margin-bottom: 20px;
  font-size: 16px;
}

.box .header-title small {
  font-size: 12px;
  color: var(--text-tip-color);
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 240px;
  min-height: 80px;
  cursor: pointer;
}

.item_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
}

.col-del {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>

<style >
.el-form.act-form .el-alert__content {
  width: 100%;
}
</style>